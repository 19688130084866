import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useThemeStore = defineStore('theme', () => {
  const isDark = ref(localStorage.getItem('theme') === 'dark')

  const toggleTheme = () => {
    isDark.value = !isDark.value
    updateTheme()
  }

  const updateTheme = () => {
    localStorage.setItem('theme', isDark.value ? 'dark' : 'light')
    document.documentElement.setAttribute('data-bs-theme', isDark.value ? 'dark' : 'light')
  }

  // Initialize theme
  updateTheme()

  return {
    isDark,
    toggleTheme
  }
})