const defaultCity = {
  name: 'Sacramento',
  lat: 38.5816,
  lng: -121.4944
}

class GeoLocationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'GeoLocationError'
  }
}

export const GeoLocationService = {
  async getCurrentPosition() {
    if (!navigator.geolocation) {
      throw new GeoLocationError('Geolocation is not supported')
    }

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          timeout: 10000,
          maximumAge: 0,
          enableHighAccuracy: true
        })
      })
      
      return {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    } catch (error) {
      console.warn('Geolocation failed:', error)
      return null
    }
  },

  async getCityFromCoords(lat, lng) {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`,
        {
          headers: {
            'Accept': 'application/json',
            'User-Agent': 'Citihub/1.0'
          }
        }
      )
      
      if (!response.ok) {
        throw new GeoLocationError('Failed to fetch city data')
      }
      
      const data = await response.json()
      return data.address?.city || data.address?.town || defaultCity.name
    } catch (error) {
      console.warn('Reverse geocoding failed:', error)
      return defaultCity.name
    }
  }
}