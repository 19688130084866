import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { initKeycloak } from './services/keycloak'
import './registerSW'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './style.css'

const app = createApp(App)
const pinia = createPinia()

const startApp = async () => {
  try {
    await initKeycloak()
    
    app.use(pinia)
    app.use(router)
    
    // Global error handler
    app.config.errorHandler = (err, vm, info) => {
      console.error('Global error:', err)
      console.error('Error info:', info)
    }
    
    app.mount('#app')
  } catch (error) {
    console.error('Failed to initialize application:', error)
    // Mount the app anyway to show error state or login options
    app.use(pinia)
    app.use(router)
    app.mount('#app')
  }
}

startApp()