import { createRouter, createWebHistory } from 'vue-router'
import { getKeycloak } from '../services/keycloak'
import Home from '../views/Home.vue'
import WorkInProgress from '../views/WorkInProgress.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { 
        title: 'Home',
        requiresAuth: false 
      }
    },
    {
      path: '/inbox',
      name: 'inbox',
      component: () => import('../views/Inbox.vue'),
      meta: {
        title: 'Inbox',
        requiresAuth: true
      }
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('../views/Feedback.vue'),
      meta: {
        title: 'Feedback',
        requiresAuth: true
      }
    },
    {
      path: '/rideshare',
      name: 'rideshare',
      component: () => import('../views/RideShare.vue'),
      meta: {
        title: 'Ride Share',
        requiresAuth: true
      }
    },
    {
      path: '/services',
      name: 'services',
      component: () => {
        const keycloak = getKeycloak();
        const hasTestUserRole = keycloak.tokenParsed?.realm_access?.roles?.includes('test_user');
        return hasTestUserRole ? import('../views/ServiceDirectory.vue') : WorkInProgress;
      },
      meta: {
        title: 'Service Directory',
        requiresAuth: false
      }
    },
    {
      path: '/events',
      name: 'events',
      component: () => {
        const keycloak = getKeycloak();
        const hasTestUserRole = keycloak.tokenParsed?.realm_access?.roles?.includes('test_user');
        return hasTestUserRole ? import('../views/Events.vue') : WorkInProgress;
      },
      meta: {
        title: 'Events',
        requiresAuth: false
      }
    },
    {
      path: '/marketplace',
      name: 'marketplace',
      component: () => {
        const keycloak = getKeycloak();
        const hasTestUserRole = keycloak.tokenParsed?.realm_access?.roles?.includes('test_user');
        return hasTestUserRole ? import('../views/Marketplace.vue') : WorkInProgress;
      },
      meta: {
        title: 'Marketplace',
        requiresAuth: true
      }
    },
    {
      path: '/chef',
      name: 'chef',
      component: () => {
        const keycloak = getKeycloak();
        const hasTestUserRole = keycloak.tokenParsed?.realm_access?.roles?.includes('test_user');
        return hasTestUserRole ? import('../views/HomeChef.vue') : WorkInProgress;
      },
      meta: {
        title: 'Home Chef',
        requiresAuth: true
      }
    },
    {
      path: '/media',
      name: 'media',
      component: () => {
        const keycloak = getKeycloak();
        const hasTestUserRole = keycloak.tokenParsed?.realm_access?.roles?.includes('test_user');
        return hasTestUserRole ? import('../views/MediaShare.vue') : WorkInProgress;
      },
      meta: {
        title: 'Media Share',
        requiresAuth: true
      }
    },
    {
      path: '/buddy',
      name: 'buddy',
      component: () => {
        const keycloak = getKeycloak();
        const hasTestUserRole = keycloak.tokenParsed?.realm_access?.roles?.includes('test_user');
        return hasTestUserRole ? import('../views/BuddyMatch.vue') : WorkInProgress;
      },
      meta: {
        title: 'Buddy Match',
        requiresAuth: true
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/Profile.vue'),
      meta: {
        title: 'Profile',
        requiresAuth: true
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('../views/Settings.vue'),
      meta: {
        title: 'Settings',
        requiresAuth: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/About.vue'),
      meta: {
        title: 'About',
        requiresAuth: false
      }
    },
    // About section routes
    {
      path: '/about/vision',
      name: 'vision',
      component: () => import('../views/about/Vision.vue'),
      meta: {
        title: 'Our Vision',
        requiresAuth: false
      }
    },
    {
      path: '/about/team',
      name: 'team',
      component: () => import('../views/about/Team.vue'),
      meta: {
        title: 'Our Team',
        requiresAuth: false
      }
    },
    {
      path: '/about/releases',
      name: 'releases',
      component: () => import('../views/about/Releases.vue'),
      meta: {
        title: 'Releases',
        requiresAuth: false
      }
    },
    {
      path: '/about/financials',
      name: 'financials',
      component: () => import('../views/about/Financials.vue'),
      meta: {
        title: 'Financials',
        requiresAuth: false
      }
    },
    {
      path: '/about/terms',
      name: 'terms',
      component: () => import('../views/about/Terms.vue'),
      meta: {
        title: 'Terms & Conditions',
        requiresAuth: false
      }
    },
    {
      path: '/about/privacy',
      name: 'privacy',
      component: () => import('../views/about/Privacy.vue'),
      meta: {
        title: 'Privacy Policy',
        requiresAuth: false
      }
    },
    {
      path: '/about/accessibility',
      name: 'accessibility',
      component: () => import('../views/about/Accessibility.vue'),
      meta: {
        title: 'Accessibility',
        requiresAuth: false
      }
    },
    {
      path: '/about/contact',
      name: 'contact',
      component: () => import('../views/about/Contact.vue'),
      meta: {
        title: 'Contact Us',
        requiresAuth: false
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../views/NotFound.vue'),
      meta: { 
        title: '404 Not Found',
        requiresAuth: false 
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title} - Citihub`
  
  if (to.meta.requiresAuth) {
    const keycloak = getKeycloak()
    if (keycloak.authenticated) {
      next()
    } else {
      try {
        await keycloak.login({
          redirectUri: window.location.origin + to.fullPath
        })
      } catch (error) {
        console.error('Login failed:', error)
        next('/')
      }
    }
  } else {
    next()
  }
})

export default router