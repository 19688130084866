<template>
  <Transition name="fade">
    <div v-if="!cookieStore.hasConsented" class="cookie-preferences">
      <div class="cookie-dialog">
        <h5>Cookie Preferences</h5>
        <p class="text-muted mb-3">
          We use cookies to enhance your experience. Choose your preferences below.
        </p>

        <div class="preferences-list mb-3">
          <div class="form-check mb-2">
            <input
              type="checkbox"
              class="form-check-input"
              id="necessary"
              checked
              disabled
            >
            <label class="form-check-label" for="necessary">
              <strong>Necessary</strong>
              <small class="d-block text-muted">Required for the website to function</small>
            </label>
          </div>

          <div class="form-check mb-2">
            <input
              type="checkbox"
              class="form-check-input"
              id="analytics"
              v-model="preferences.analytics"
            >
            <label class="form-check-label" for="analytics">
              <strong>Analytics</strong>
              <small class="d-block text-muted">Help us improve our website</small>
            </label>
          </div>

        </div>

        <div class="d-flex gap-2">
          <button 
            class="btn btn-outline-secondary" 
            @click="acceptNecessaryOnly"
            :disabled="!preferences.termsAccepted"
          >
            Necessary Only
          </button>
          <button 
            class="btn btn-primary" 
            @click="acceptAll"
            :disabled="!preferences.termsAccepted"
          >
            Accept All
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { reactive } from 'vue'
import { useCookieConsentStore } from '../../stores/cookieConsent'

const cookieStore = useCookieConsentStore()

const preferences = reactive({
  analytics: false,
  termsAccepted: false
})

const acceptAll = () => {
  if (!preferences.termsAccepted) return
  
  cookieStore.setConsent({
    necessary: true,
    analytics: true
  })
}

const acceptNecessaryOnly = () => {
  if (!preferences.termsAccepted) return
  
  cookieStore.setConsent({
    necessary: true,
    analytics: false
  })
}
</script>

<style scoped>
.cookie-preferences {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bs-body-bg);
  border-top: 1px solid var(--bs-border-color);
  padding: 1rem;
  z-index: 1050;
}

.cookie-dialog {
  max-width: 600px;
  margin: 0 auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

[data-bs-theme="dark"] .cookie-preferences {
  background-color: var(--bs-dark);
  border-color: var(--bs-dark-border-subtle);
}
</style>