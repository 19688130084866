<script setup>
import { ref, computed, onMounted } from 'vue'
import { useCityStore } from '../stores/city'
import { GeoLocationService } from '../services/GeoLocationService'
import { popularCities } from '../utils/cityData'

const cityStore = useCityStore()
const currentCity = ref(cityStore.currentCity || 'Loading...')
const showModal = ref(false)
const searchQuery = ref('')
const error = ref(null)
const showUnsupportedBanner = ref(false)

const filteredCities = computed(() => {
  if (!searchQuery.value) return popularCities
  return popularCities.filter(city => 
    city.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
})

const initializeLocation = async () => {
  try {
    const position = await GeoLocationService.getCurrentPosition()
    if (position) {
      const city = await GeoLocationService.getCityFromCoords(position.lat, position.lng)
      if (popularCities.includes(city)) {
        selectCity(city)
      } else {
        showUnsupportedBanner.value = true
        selectCity('Sacramento') // Default to Sacramento if city not supported
      }
    } else {
      selectCity('Sacramento') // Default city
    }
  } catch (err) {
    console.error('Failed to initialize location:', err)
    error.value = 'Could not detect location'
    selectCity('Sacramento') // Fallback to default
  }
}

const selectCity = (city) => {
  try {
    currentCity.value = city
    cityStore.setCity(city)
    showModal.value = false
  } catch (err) {
    console.error('Failed to select city:', err)
    error.value = 'Could not update city'
  }
}

const toggleModal = (value) => {
  try {
    showModal.value = value
  } catch (err) {
    console.error('Failed to toggle modal:', err)
  }
}

onMounted(() => {
  if (!cityStore.currentCity) {
    initializeLocation()
  }
})
</script>

<template>
  <div class="city-selector">
    <!-- Unsupported City Banner -->
    <div v-if="showUnsupportedBanner" class="alert alert-warning alert-dismissible fade show" role="alert">
      Citihub is in a limited geographical rollout phase. Only cities in the Sacramento, El Dorado and Placer county are supported in this phase.
      <button type="button" class="btn-close" @click="showUnsupportedBanner = false" aria-label="Close"></button>
    </div>

    <!-- City Link in Navbar -->
    <a 
      href="#" 
      class="nav-link" 
      @click.prevent="toggleModal(true)"
    >
      <i class="bi bi-geo-alt me-1"></i>
      {{ currentCity }}
    </a>

    <!-- City Selection Modal -->
    <div 
      v-if="showModal"
      class="modal fade show" 
      tabindex="-1" 
      style="display: block;"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Your City</h5>
            <button 
              type="button" 
              class="btn-close" 
              @click="toggleModal(false)"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ error }}
            </div>
            
            <!-- Search Input -->
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Search cities..."
                v-model="searchQuery"
              >
            </div>

            <!-- Cities List -->
            <div class="list-group">
              <button
                v-for="city in filteredCities"
                :key="city"
                class="list-group-item list-group-item-action"
                @click="selectCity(city)"
              >
                {{ city }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Backdrop -->
    <div 
      v-if="showModal"
      class="modal-backdrop fade show"
    ></div>
  </div>
</template>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background-color: var(--bs-light);
}

[data-bs-theme="dark"] .list-group-item:hover {
  background-color: var(--bs-dark);
}

.alert {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1060;
  width: auto;
  max-width: 90%;
  margin: 0;
}
</style>