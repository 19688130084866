<script setup>
import { ref } from 'vue'

const links = [
  {
    to: '/inbox',
    icon: 'bi-inbox-fill',
    text: 'Inbox'
  },
  {
    type: 'dropdown',
    text: 'About',
    items: [
      {
        to: '/feedback',
        icon: 'bi-chat-dots-fill',
        text: 'Feedback'
      },
      {
        to: '/about/vision',
        icon: 'bi-eye-fill',
        text: 'Vision'
      },
      {
        to: '/about/releases',
        icon: 'bi-rocket-fill',
        text: 'Releases'
      },
      {
        to: '/about/team',
        icon: 'bi-people-fill',
        text: 'Team'
      },
      {
        to: '/about/financials',
        icon: 'bi-graph-up',
        text: 'Financials'
      }
    ]
  }
]
</script>

<template>
  <ul class="navbar-nav me-auto">
    <li 
      v-for="link in links" 
      :key="link.to || link.text" 
      :class="['nav-item', { 'dropdown': link.type === 'dropdown' }]"
    >
      <!-- Regular Link -->
      <router-link 
        v-if="!link.type"
        class="nav-link" 
        :to="link.to"
      >
        <i :class="link.icon"></i>
        <span class="ms-1">{{ link.text }}</span>
      </router-link>

      <!-- Dropdown -->
      <template v-else-if="link.type === 'dropdown'">
        <a 
          class="nav-link dropdown-toggle" 
          href="#" 
          role="button" 
          data-bs-toggle="dropdown"
        >
          {{ link.text }}
        </a>
        <ul class="dropdown-menu">
          <li v-for="item in link.items" :key="item.to">
            <router-link 
              class="dropdown-item" 
              :to="item.to"
            >
              <i :class="item.icon" class="me-2"></i>
              {{ item.text }}
            </router-link>
          </li>
        </ul>
      </template>
    </li>
  </ul>
</template>

<style scoped>
.nav-link i {
  font-size: 1.1rem;
}

.dropdown-item {
  padding: 0.5rem 1rem;
}

.dropdown-item i {
  font-size: 1rem;
}

[data-bs-theme="dark"] .dropdown-menu {
  background-color: var(--bs-dark);
  border-color: var(--bs-dark-border-subtle);
}

[data-bs-theme="dark"] .dropdown-item {
  color: var(--bs-light);
}

[data-bs-theme="dark"] .dropdown-item:hover {
  background-color: var(--bs-dark-bg-subtle);
}
</style>