<script setup>
import { computed } from 'vue'
import { getKeycloak } from '@/services/keycloak'
import UserAvatar from './UserAvatar.vue'
import LoginButton from './LoginButton.vue'

const keycloak = getKeycloak()
const isAuthenticated = computed(() => keycloak.authenticated)
</script>

<template>
  <div class="auth-section">
    <UserAvatar v-if="isAuthenticated" />
    <LoginButton v-else />
  </div>
</template>