<script setup>
const currentYear = new Date().getFullYear()

const footerLinks = [
  { to: '/about/terms', text: 'Terms of Use' },
  { to: '/about/privacy', text: 'Privacy Policy' },
  { to: '/about/accessibility', text: 'Accessibility' },
  { to: '/about/contact', text: 'Contact Us' }
]
</script>

<template>
  <footer class="footer mt-auto py-3">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-center text-md-start">
          <p class="mb-0">&copy; {{ currentYear }} Citihub. All rights reserved.</p>
        </div>
        <div class="col-md-6 text-center text-md-end">
          <router-link 
            v-for="link in footerLinks"
            :key="link.to"
            :to="link.to" 
            class="text-decoration-none me-3"
          >
            {{ link.text }}
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  margin-top: auto;
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  border-top: 1px solid var(--bs-border-color);
  transition: background-color 0.3s, color 0.3s;
}

[data-bs-theme="dark"] .footer {
  background-color: var(--bs-dark);
  color: var(--bs-light);
  border-color: var(--bs-dark-border-subtle);
}

.router-link-active {
  color: var(--bs-primary);
}
</style>