export const popularCities = [
  // Sacramento County
  'Sacramento',
  'Elk Grove',
  'Citrus Heights',
  'Rancho Cordova',
  'Folsom',
  'Galt',
  'Isleton',
  // El Dorado County
  'Placerville',
  'South Lake Tahoe',
  'Cameron Park',
  'El Dorado Hills',
  'Diamond Springs',
  // Placer County
  'Roseville',
  'Rocklin',
  'Lincoln',
  'Auburn',
  'Loomis',
  'Colfax'
]