<script setup>
import { getKeycloak } from '@/services/keycloak'

const login = () => {
  const keycloak = getKeycloak()
  keycloak.login()
}
</script>

<template>
  <button @click="login" class="btn btn-primary">
    <i class="bi bi-box-arrow-in-right me-2"></i>
    Login
  </button>
</template>