import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCityStore = defineStore('city', () => {
  const currentCity = ref(localStorage.getItem('selectedCity') || '')

  const setCity = (city) => {
    if (!city) {
      console.warn('Attempted to set empty city')
      return
    }
    
    try {
      currentCity.value = city
      localStorage.setItem('selectedCity', city)
    } catch (error) {
      console.error('Failed to save city:', error)
      throw new Error('Failed to save city preference')
    }
  }

  return {
    currentCity,
    setCity
  }
})