/**
 * Get environment variable with validation
 * @param {string} key - Environment variable key
 * @param {string} [defaultValue] - Optional default value
 * @returns {string} Environment variable value
 * @throws {Error} If environment variable is not set and no default value provided
 */
export const getEnvVar = (key, defaultValue) => {
  const value = import.meta.env[key] || defaultValue

  if (value === undefined) {
    throw new Error(`Required environment variable ${key} is not set`)
  }

  return value
}

/**
 * Get API URL from environment
 * @returns {string} API URL
 */
export const getApiUrl = () => {
  return getEnvVar('VITE_API_URL', 'http://localhost:3000/api')
}

/**
 * Get Keycloak configuration from environment
 * @returns {Object} Keycloak configuration
 */
export const getKeycloakConfig = () => {
  return {
    url: getEnvVar('VITE_KEYCLOAK_URL'),
    realm: getEnvVar('VITE_KEYCLOAK_REALM'),
    clientId: getEnvVar('VITE_KEYCLOAK_CLIENT_ID')
  }
}