<script setup>
import { ref } from 'vue'
import CityHubLogo from './CityHubLogo.vue'
import NavLinks from './navigation/NavLinks.vue'
import NavbarActions from './navigation/NavbarActions.vue'

const isNavOpen = ref(false)

const toggleNav = () => {
  isNavOpen.value = !isNavOpen.value
}

const closeNav = (event) => {
  // Don't close if clicking user avatar or its dropdown
  if (event.target.closest('.user-avatar') || event.target.closest('.dropdown-menu')) {
    return
  }
  isNavOpen.value = false
}
</script>

<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <CityHubLogo />
      </router-link>
      
      <button 
        class="navbar-toggler" 
        type="button" 
        @click="toggleNav"
        :aria-expanded="isNavOpen"
      >
        <i class="bi" :class="isNavOpen ? 'bi-x-lg' : 'bi-list'"></i>
      </button>
      
      <div 
        class="collapse navbar-collapse" 
        :class="{ show: isNavOpen }"
        id="navbarNav"
        @click="closeNav"
      >
        <NavLinks />
        <NavbarActions />
      </div>
    </div>
  </nav>
</template>

<style scoped>
.navbar {
  box-shadow: 0 2px 4px rgba(0,0,0,.08);
}

.navbar-toggler {
  padding: 0.5rem;
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.bi-x-lg, .bi-list {
  font-size: 1.5rem;
}

[data-bs-theme="dark"] .navbar {
  box-shadow: 0 2px 4px rgba(0,0,0,.2);
}
</style>