import Keycloak from 'keycloak-js'
import { getEnvVar } from '../utils/env'
import { getMockKeycloak } from './__mocks__/keycloak'

const keycloakConfig = {
  url: getEnvVar('VITE_KEYCLOAK_URL'),
  realm: getEnvVar('VITE_KEYCLOAK_REALM'),
  clientId: getEnvVar('VITE_KEYCLOAK_CLIENT_ID'),
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  checkLoginIframe: false
}
console.log('Keycloak Mode:', import.meta.env.MODE);

const keycloakInstance = import.meta.env.MODE !== 'production' 
  ? getMockKeycloak()
  : new Keycloak(keycloakConfig)

export const initKeycloak = () => {
  return new Promise((resolve) => {
    try {
      keycloakInstance
        .init({
          onLoad: keycloakConfig.onLoad,
          silentCheckSsoRedirectUri: keycloakConfig.silentCheckSsoRedirectUri,
          checkLoginIframe: keycloakConfig.checkLoginIframe,
          enableLogging: true,
          pkceMethod: 'S256'
        })
        .then((authenticated) => {
          console.log('Keycloak initialized, authenticated:', authenticated)
          if (authenticated) {
            refreshTokenPeriodically()
          }
          resolve(authenticated)
        })
        .catch((error) => {
          console.warn('Keycloak initialization failed:', error)
          resolve(false)
        })
    } catch (error) {
      console.warn('Keycloak initialization error:', error)
      resolve(false)
    }
  })
}

const refreshTokenPeriodically = () => {
  setInterval(() => {
    keycloakInstance.updateToken(70)
      .catch(() => {
        console.log('Failed to refresh token, logging out...')
        keycloakInstance.logout()
      })
  }, 60000)
}

export const getKeycloak = () => keycloakInstance