import { defineStore } from 'pinia'
import { ref } from 'vue'
import { storage } from '../utils/storage'

export const useCookieConsentStore = defineStore('cookieConsent', () => {
  const hasConsented = ref(storage.get('cookieConsent') || false)
  const preferences = ref(storage.get('cookiePreferences') || {
    necessary: true, // Always true
    analytics: false,
    marketing: false
  })

  const setConsent = (newPreferences) => {
    preferences.value = {
      ...newPreferences,
      necessary: true // Always keep necessary cookies
    }
    hasConsented.value = true
    storage.set('cookieConsent', true)
    storage.set('cookiePreferences', preferences.value)
  }

  return {
    hasConsented,
    preferences,
    setConsent
  }
})