<script setup>
import { useThemeStore } from '../stores/theme'

const themeStore = useThemeStore()
</script>

<template>
  <div class="theme-toggle">
    <button 
      class="btn btn-link nav-link px-2" 
      @click="themeStore.toggleTheme"
      :title="themeStore.isDark ? 'Switch to Light Mode' : 'Switch to Dark Mode'"
    >
      <i class="bi" :class="themeStore.isDark ? 'bi-sun' : 'bi-moon'"></i>
    </button>
  </div>
</template>

<style scoped>
.theme-toggle {
  display: flex;
  align-items: center;
}

.btn-link {
  color: inherit;
  text-decoration: none;
}

.bi {
  font-size: 1.2rem;
}
</style>