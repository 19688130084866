<template>
  <div class="container mt-5 text-center">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <i class="bi bi-gear-wide-connected display-1 text-primary mb-4"></i>
        <h1>Work in Progress</h1>
        <p class="lead">This feature is currently under development.</p>
      </div>
    </div>
  </div>
</template>