<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getKeycloak } from '@/services/keycloak'

const router = useRouter()
const keycloak = getKeycloak()
const dropdownOpen = ref(false)

const userInitials = computed(() => {
  const name = keycloak.tokenParsed?.name || 'User'
  return name.split(' ').map(n => n[0]).join('').toUpperCase()
})

const logout = () => {
  keycloak.logout()
}

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value
}

const goToProfile = () => {
  router.push('/profile')
  dropdownOpen.value = false
}

const goToSettings = () => {
  router.push('/settings')
  dropdownOpen.value = false
}
</script>

<template>
  <div class="nav-item dropdown">
    <div class="user-avatar" @click="toggleDropdown" role="button">
      {{ userInitials }}
    </div>
    
    <ul class="dropdown-menu dropdown-menu-end" :class="{ show: dropdownOpen }">
      <li>
        <a class="dropdown-item" href="#" @click.prevent="goToProfile">
          <i class="bi bi-person me-2"></i>Profile
        </a>
      </li>
      <li>
        <a class="dropdown-item" href="#" @click.prevent="goToSettings">
          <i class="bi bi-gear me-2"></i>Settings
        </a>
      </li>
      <li><hr class="dropdown-divider"></li>
      <li>
        <a class="dropdown-item" href="#" @click.prevent="logout">
          <i class="bi bi-box-arrow-right me-2"></i>Logout
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  background-color: #6c757d;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
}

.user-avatar:hover {
  background-color: #5a6268;
}

.dropdown-menu {
  margin-top: 0.5rem;
}

.dropdown-item {
  padding: 0.5rem 1rem;
}

.dropdown-item:hover {
  background-color: var(--bs-light);
}

[data-bs-theme="dark"] .dropdown-item:hover {
  background-color: var(--bs-dark);
}
</style>