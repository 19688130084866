<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { getKeycloak } from '../services/keycloak'

const router = useRouter()
const keycloak = getKeycloak()
const isAuthenticated = computed(() => keycloak.authenticated)

const features = [
  { 
    icon: 'bi-car-front', 
    title: 'Ride Share', 
    description: 'Connect with drivers and riders', 
    color: 'primary',
    route: '/rideshare',
    requiresAuth: true
  },
  { 
    icon: 'bi-building', 
    title: 'Service Directory', 
    description: 'Find trusted service providers', 
    color: 'success',
    route: '/services',
    requiresAuth: false
  },
  { 
    icon: 'bi-shop', 
    title: 'Reuse Marketplace', 
    description: 'Buy and sell pre-owned items', 
    color: 'warning',
    route: '/marketplace',
    requiresAuth: true
  },
  { 
    icon: 'bi-calendar-event', 
    title: 'Events', 
    description: 'Discover local events', 
    color: 'danger',
    route: '/events',
    requiresAuth: false
  },
  { 
    icon: 'bi-egg-fried', 
    title: 'Home Chef', 
    description: 'Share meals and recipes', 
    color: 'success',
    route: '/chef',
    requiresAuth: true
  },
  { 
    icon: 'bi-book', 
    title: 'Media Share', 
    description: 'Exchange books and media', 
    color: 'primary',
    route: '/media',
    requiresAuth: true
  },
  { 
    icon: 'bi-heart', 
    title: 'Buddy Match', 
    description: 'Connect with like-minded people', 
    color: 'danger',
    route: '/buddy',
    requiresAuth: true
  }
]

const handleFeatureClick = (feature) => {
  if (feature.route) {
    router.push(feature.route)
  }
}
</script>

<template>
  <div class="container mt-5">
    <div class="row text-center mb-4">
      <div class="col">
        <h1 class="display-4">Welcome to Citihub</h1>
        <p class="lead">Your Urban Connection Platform</p>
      </div>
    </div>

    <div class="row g-3 justify-content-center">
      <div v-for="feature in features" :key="feature.title" class="col-6 col-md-4 col-lg-3">
        <div 
          class="card h-100 text-center shadow-sm"
          :class="{ 'cursor-pointer': feature.route }"
          @click="handleFeatureClick(feature)"
        >
          <div class="card-body d-flex flex-column py-3">
            <div class="feature-icon mb-2">
              <i :class="[feature.icon, `text-${feature.color}`]" class="bi"></i>
            </div>
            <h6 class="card-title mb-1">{{ feature.title }}</h6>
            <p class="card-text small text-muted mb-0">{{ feature.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bi {
  font-size: 2rem;
}

.card {
  transition: transform 0.2s, box-shadow 0.2s;
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.feature-icon {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-text {
  font-size: 0.875rem;
  line-height: 1.2;
}
</style>